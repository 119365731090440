






































































































































































































































































































































































































































































import Vue from "vue";
import ClientStoreModule from "@/store/modules/client";
import { createNamespacedHelpers } from "vuex";
import { Business, Client, Gift, Membership, Role } from "@/types";
import InventoryStoreModule from "@/store/modules/productInventory";
import ServiceStoreModule from "@/store/modules/service";
import ServiceCategoryModule from "@/store/modules/serviceCategory";
import VoucherStoreModule from "@/store/modules/voucher";
import MembershipStoreModule from "@/store/modules/membership";
import servicePackageModule from "@/store/modules/servicePackage";

const { mapGetters: clientGetters, mapActions: clientActions } =
  createNamespacedHelpers("CLIENT_");
const { mapGetters: InventoryGetters, mapActions: InventoryActions } =
  createNamespacedHelpers("SKUS_");
const { mapGetters: serviceGetters, mapActions: serviceActions } =
  createNamespacedHelpers("SERVICE_CHECKOUT");
const {
  mapActions: serviceCategoryActions,
  mapGetters: serviceCategoryGetters,
} = createNamespacedHelpers("SERVICE_CAT_CHECKOUT");

const { mapGetters: voucherGetters, mapActions: voucherActions } =
  createNamespacedHelpers("VOUCHER_");
const { mapGetters: membershipGetters, mapActions: membershipActions } =
  createNamespacedHelpers("MEMBERSHIP_STEPPER");

const { mapActions: servicePackageActions, mapGetters: servicePackageGetters } =
  createNamespacedHelpers("SERVICE_PACKAGE_CHECKOUT");

const itemOptions = [
  { title: "Products", value: "product", desc: "Products To Add To Cart" },
  { title: "Services", value: "service", desc: "Services To Add To Cart" },
];

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  name: "CheckoutStepper",
  props: {
    redirect: {
      type: String,
      default: "/checkout",
    },
  },
  data: () => ({
    step: 1,
    isLoadingClients: false,
    searchClients: null,
    selectedClient: null,
    items: itemOptions,
    selectedItem: null,
    selectedProducts: [],
    selectedServiceCategory: null,
    selectedServices: [],
    isLoadingServices: false,
    membershipForm: {
      program: null as any | Membership,
      frequency: null,
      duration: 1,
    },
    currentFrequencies: [] as any[],
    selectedMemberships: [] as any[],
    selectedVouchers: [] as any[],
    serviceCategorySelected: false,
    apiUrl: VUE_APP_API_URL,
    servicePackages: [],
  }),
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
    ...clientGetters(["clientPage"]),
    ...membershipGetters(["membershipPage"]),
    ...voucherGetters(["voucherPage"]),
    ...servicePackageGetters(["servicePackagePage"]),
    clients(): Client[] {
      return this.clientPage.docs;
    },
    isProducts(): boolean {
      return this.selectedItem == "product" ? true : false;
    },
    ...InventoryGetters(["skus"]),
    ...serviceGetters({
      services: "servicePage",
    }),
    ...serviceCategoryGetters({
      service_categories: "serviceCategoryPage",
    }),
    client(): any {
      return this.$store.getters["cart/client"];
    },
    memberships(): Membership[] {
      return this.membershipPage?.docs || [];
    },
  },
  watch: {
    selectedServiceCategory: {
      deep: true,
      handler(val) {
        this.fetchServices();

        if (!val) {
          this.services.docs = [];
          this.isLoadingServices = false;
        }
      },
    },
    role() {
      if (this.role) {
        const params = `?businessId=${(this.role.business as Business)?._id}`;
        this.fetchMembershipList(params);
      }
    },
    membershipForm: {
      deep: true,
      handler() {
        this.currentFrequencies = [];
        const { program } = this.membershipForm;
        if (program) {
          const _program = program as Membership;
          const data: any[] = Object.entries(_program.price).map((item) => {
            return {
              type: item[0],
              price: item[1] || 0,
            };
          });
          this.currentFrequencies = data;
        }
      },
    },
  },
  mounted() {
    if (this.role) {
      const bid = (this.role.business as Business)?._id;
      const params = `?businessId=${bid}&limit=10000`;
      this.fetchMembershipList(params);
      this.fetchVoucherList(params);
    }
  },
  created() {
    this.loadClients();
    this.loadProducts();
    this.loadServices();
    this.resetCheckout();
  },
  methods: {
    ...clientActions(["fetchClientList"]),
    ...InventoryActions(["fetchProductSkus"]),
    ...serviceActions(["fetchServiceList"]),
    ...serviceCategoryActions(["fetchServiceCategoryList"]),
    ...membershipActions(["fetchMembershipList"]),
    ...voucherActions(["fetchVoucherList"]),
    ...servicePackageActions(["fetchServicePackageList"]),
    addMembership() {
      if (this.membershipForm.program && this.membershipForm.frequency) {
        const { program, frequency, duration } = this.membershipForm;
        this.selectedMemberships.push({
          membership: program,
          frequency: frequency,
          duration: duration,
        });
        this.membershipForm.program = null;
        this.membershipForm.frequency = null;
        this.membershipForm.duration = 1;
      }
    },
    close() {
      this.$emit("close");
    },
    goToCheckout() {
      const route = this.$route.path == this.redirect;
      if (!route) {
        this.$router.push(this.redirect);
      }
    },
    loadClients(q?: string) {
      if (this.role) {
        let params = `?businessId=${(this.role.business as Business)?._id}`;
        if (q) params += `&q=${q}`;
        this.fetchClientList(params).finally(
          () => (this.isLoadingClients = false)
        );
      }
    },
    loadProducts() {
      if (this.role) {
        const params = `?businessId=${(this.role.business as Business)?._id}`;
        this.fetchProductSkus(params);
      }
    },
    loadServices() {
      if (this.role) {
        const params = `?businessId=${(this.role.business as Business)?._id}`;
        this.fetchServiceCategoryList(params);
      }
    },
    loadServicePackages(q?: string) {
      if (this.role) {
        let params = `?businessId=${(this.role.business as Business)?._id}`;
        if (q) params += `&q=${q}`;
        this.fetchServicePackageList(params);
      }
    },
    getProductImg(image: string) {
      return `${VUE_APP_API_URL}/v1/file/${image}`;
    },
    clientsFilter(item: Client, queryText: string, itemText: string) {
      const name = item.fullName;
      const email = item.email;
      const phone = item.phone;
      const search = queryText.toLowerCase();
      return (
        name.indexOf(search) > -1 ||
        email.indexOf(search) > -1 ||
        phone.indexOf(search) > -1
      );
    },
    cartUpdate() {
      const p5s = this.selectedVouchers?.map((item: any) => {
        return this.$store.dispatch("cart/getVoucher", {
          voucherId: item._id,
          maxUsageCount: 1,
          quantity: 1,
        });
      });
      const p1s = this.selectedMemberships?.map((item: any) => {
        return this.$store.dispatch("cart/getMembership", {
          membershipId: item.membership._id,
          type: item.frequency.type,
          quantity: 1,
          duration: item.duration,
          period: item.duration
            ? `${item.duration} ${item.membership.duration.duration_type}`
            : "N/A",
        });
      });

      const p2s = this.selectedProducts?.map((item: any) => {
        return this.$store.dispatch("cart/getProduct", {
          productId: item.product._id,
          quantity: 1,
        });
      });

      const p3s = this.selectedServices?.map((_id: string) => {
        return this.$store.dispatch("cart/getService", {
          serviceId: _id,
          // employeeId: "",
          // durationInMinutes: 0,
          appointmentTime: "",
          appointmentDate: "",
          quantity: 1,
        });
      });

      const p4s = this.servicePackages?.map((item) => {
        return this.$store.dispatch("cart/getServicePackage", {
          servicePackageId: item._id,
          quantity: 1,
        });
      });

      const p = this.$store.dispatch("cart/setPayOnlyDeposit", false);

      const promises: any[] = [p];

      if (p1s) promises.push(...p1s);
      if (p2s) promises.push(...p2s);
      if (p3s) promises.push(...p3s);
      if (p4s) promises.push(...p4s);
      if (p5s) promises.push(...p5s);

      Promise.all(promises).then(() => {
        this.resetCheckout();
        this.goToCheckout();
        this.close();
      });
    },
    resetCheckout() {
      if (this.client) {
        this.selectedClient = this.client;
      }
      this.selectedItem = null;
      this.selectedProducts = [];
      this.selectedServiceCategory = null;
      this.selectedServices = [];
      this.selectedMemberships = [];
      this.selectedVouchers = null;
    },
    updateCartClient() {
      if (this.selectedClient) {
        this.$store.dispatch("cart/addCartClient", this.selectedClient);
      }
    },
    removeProduct(item: any) {
      const index = (this.selectedProducts as any[]).indexOf(item);
      if (index >= 0) (this.selectedProducts as any[]).splice(index, 1);
    },
    removeClient() {
      this.selectedClient = null;
    },
    removeServiceCat(item: any) {
      this.selectedServiceCategory = this.selectedServiceCategory.filter(
        (x) => x._id != item._id
      );
      this.selectedServices = [];
    },
    removeService(id: string) {
      console.log("remove: ", id);
      const index = this.selectedServices.findIndex((item) => item === id);

      if (index > -1) this.selectedServices.splice(index, 1);
    },
    removeMembership(index: number) {
      this.selectedMemberships.splice(index);
    },
    fetchServices(q?: string) {
      if (!this.selectedServiceCategory) return;
      this.isLoadingServices = true;
      const ids = this.selectedServiceCategory.map(
        (x) => `categoryIds=${x._id}`
      );
      let params = `?${ids.join("&")}&page=1&limit=10000`;
      if (q) params += `&q=${q}`;
      this.fetchServiceList(params).finally(
        () => (this.isLoadingServices = false)
      );
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CLIENT_")) {
      this.$store.registerModule("CLIENT_", ClientStoreModule);
    }

    if (!this.$store.hasModule("SKUS_")) {
      this.$store.registerModule("SKUS_", InventoryStoreModule);
    }

    if (!this.$store.hasModule("SERVICE_CHECKOUT")) {
      this.$store.registerModule("SERVICE_CHECKOUT", ServiceStoreModule);
    }

    if (!this.$store.hasModule("SERVICE_CAT_CHECKOUT")) {
      this.$store.registerModule("SERVICE_CAT_CHECKOUT", ServiceCategoryModule);
    }

    if (!this.$store.hasModule("VOUCHER_")) {
      this.$store.registerModule("VOUCHER_", VoucherStoreModule);
    }

    if (!this.$store.hasModule("MEMBERSHIP_STEPPER")) {
      this.$store.registerModule("MEMBERSHIP_STEPPER", MembershipStoreModule);
    }

    if (!this.$store.hasModule("SERVICE_PACKAGE_CHECKOUT")) {
      this.$store.registerModule(
        "SERVICE_PACKAGE_CHECKOUT",
        servicePackageModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CLIENT_");
    this.$store.unregisterModule("SKUS_");
    this.$store.unregisterModule("SERVICE_CHECKOUT");
    this.$store.unregisterModule("SERVICE_CAT_CHECKOUT");
    this.$store.unregisterModule("VOUCHER_");
    this.$store.unregisterModule("MEMBERSHIP_STEPPER");
    this.$store.unregisterModule("SERVICE_PACKAGE_CHECKOUT");
  },
});
