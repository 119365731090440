





































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import { EmployeePermission } from "@/types";
import employeeStoreModule from "@/store/modules/employee";
import serviceStoreModule from "@/store/modules/service";
import serviceCategoryStoreModule from "@/store/modules/serviceCategory";
import business from "@/store/modules/business";
import { duration } from "moment";

const { mapActions, mapGetters } = createNamespacedHelpers("EMPLOYEE");
const { mapActions: serviceActions, mapGetters: serviceGetters } =
  createNamespacedHelpers("SERVICE");
const {
  mapActions: serviceCategoryActions,
  mapGetters: serviceCategoryGetters,
} = createNamespacedHelpers("SERVICE_CATEGORIES");

export default Vue.extend<any, any, any, any>({
  name: "ServiceRoomList",
  data: () => ({
    room: undefined,
    showRoomDialog: false,
    roomName: "",
    maxServicesAllowed: 0,
    selectedCategory: null,
    selectedService: [],
    headers: [
      { text: "Room Name", value: "roomName" },
      { text: "Max Allowed Allocations", value: "maxServicesAllowed" },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
    nameRules: [(v: string) => !!v || "Room Name is required"],
    allocationRules: [
      (v: number) => !!v || "Max Allowed Allocations is required",
    ],
  }),
  computed: {
    ...mapGetters(["serviceRoomPage"]), //, "roomAvailability"
    ...serviceGetters(["servicePage"]),
    ...serviceCategoryGetters(["serviceCategoryPage"]),
    services() {
      return this.servicePage.docs;
    },
    serviceCategories() {
      return this.serviceCategoryPage.docs;
    },
    permission(): EmployeePermission {
      return this.$store.getters.permission;
    },
    roomParams() {
      return `?businessId=${this.$store.getters.role.business._id}`;
    },
  },
  methods: {
    ...mapActions([
      "fetchServiceRoomList",
      "createServiceRoom",
      "updateServiceRoom",
      "deleteServiceRoom",
      // "fetchRoomAvailability",
    ]),
    ...serviceActions(["fetchServiceList"]),
    ...serviceCategoryActions(["fetchServiceCategoryList"]),
    viewRoom(roomId: string) {
      this.room = this.serviceRoomPage.docs.find((r) => r._id === roomId);
      if (this.room) {
        this.roomName = this.room.roomName;
        this.maxServicesAllowed = this.room.maxServicesAllowed;
        this.selectedService = this.room.servicesAllowed || [];
      }
    },
    fetchServices() {
      const params = `?businessId=${this.$store.getters.role.business._id}&categoryId=${this.selectedCategory}`;
      this.fetchServiceList(params);
    },
    saveRoom() {
      const valid = (
        this.$refs.roomForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      const payload = {
        roomName: this.roomName,
        business: this.$store.getters.role.business._id,
        maxServicesAllowed: this.maxServicesAllowed,
        servicesAllowed: this.selectedService,
      };

      if (this.room) {
        this.updateServiceRoom({
          serviceRoomId: this.room._id,
          ...payload,
        }).then(() => {
          this.showRoomDialog = false;
          this.fetchServiceRoomList(this.roomParams);
        });
      } else {
        this.createServiceRoom(payload).then(() => {
          this.showRoomDialog = false;
          this.fetchServiceRoomList(this.roomParams);
        });
      }
    },
    editRoom(room: any) {
      this.room = room;
      this.roomName = room.roomName;
      this.maxServicesAllowed = room.maxServicesAllowed;
      this.selectedService = room.servicesAllowed || [];
      this.showRoomDialog = true;
    },
    deleteRoom(roomId: string) {
      this.deleteServiceRoom(roomId).then(() => {
        this.fetchServiceRoomList(this.roomParams);
      });
    },
    resetForm() {
      this.room = undefined;
      this.roomName = "";
      this.maxServicesAllowed = 0;
      this.selectedService = [];
    },
    // fetchAvailability() {
    //   this.fetchRoomAvailability([
    //     {
    //       serviceId: "657aee875dc9de00251fd44f",
    //       businessId: "657aba0ecb7ead0025b592fa",
    //       startDateIso: new Date().toISOString(),
    //       duration: 20,
    //     },
    //     {
    //       serviceId: "657aed945dc9de00251fd2e3",
    //       businessId: "657aba0ecb7ead0025b592fa",
    //       startDateIso: new Date().toISOString(),
    //       duration: 20,
    //     },
    //     {
    //       serviceId: "65c33179f96aaef1c1c74d19",
    //       businessId: "657aba0ecb7ead0025b592fa",
    //       startDateIso: new Date().toISOString(),
    //       duration: 20,
    //     },
    //     {
    //       serviceId: "65c33179f96aaef1c1c74d19",
    //       businessId: "657aed3d5dc9de00251fd22c",
    //       startDateIso: new Date().toISOString(),
    //       duration: 20,
    //     },
    //   ]).then(() => {
    //     console.log(this.roomAvailability);
    //   });
    // },
  },
  mounted() {
    this.fetchServiceCategoryList();
    // this.fetchAvailability();
  },
  watch: {
    permission() {
      if (this.permission) {
        this.fetchServiceRoomList(this.roomParams);
        // this.fetchServiceCategoryList();
      }
    },
    options: {
      handler() {
        this.fetchServiceRoomList(this.roomParams);
      },
      deep: true,
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("EMPLOYEE")) {
      this.$store.registerModule("EMPLOYEE", employeeStoreModule);
    }
    if (!this.$store.hasModule("SERVICE")) {
      this.$store.registerModule("SERVICE", serviceStoreModule);
    }
    if (!this.$store.hasModule("SERVICE_CATEGORIES")) {
      this.$store.registerModule(
        "SERVICE_CATEGORIES",
        serviceCategoryStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("EMPLOYEE");
    this.$store.unregisterModule("SERVICE");
    this.$store.unregisterModule("SERVICE_CATEGORIES");
  },
});
