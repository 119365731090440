import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Voucher, Page } from "@/types";

type VoucherState = {
  voucherPage: Page<Voucher>;
};

const voucher: Module<VoucherState, unknown> = {
  namespaced: true,
  state: () => ({
    voucherPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getVoucher: (state) => (voucherId: string) =>
      state.voucherPage.docs.find((c) => c._id === voucherId),
    voucherPage: (state) => state.voucherPage,
  },
  mutations: {
    ADD_VOUCHER: (state, _voucher) => {
      let idx = -1;
      state.voucherPage.docs.map((v, i) => {
        if (v._id === _voucher._id) idx = i;
      });
      if (idx === -1) state.voucherPage.docs.push(_voucher);
      else Vue.set(state.voucherPage.docs, idx, _voucher);
    },
    SET_VOUCHER_PAGE: (state, list) => {
      state.voucherPage = list;
    },
    REMOVE_VOUCHER(state, voucher) {
      let idx = -1;
      state.voucherPage.docs.map((v, i) => {
        if (v._id === voucher._id) idx = i;
      });
      if (idx > -1) state.voucherPage.docs.splice(idx, 1);
    },
  },
  actions: {
    async fetchVoucher(context, params = "") {
      return await api
        .get(`/v1/voucher${params}`)
        .then((response) => {
          context.commit("ADD_VOUCHER", response.data.voucher);
          return response.data.voucher;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchVoucherList(context, params = "") {
      api
        .get(`/v1/voucher${params}`)
        .then((response) => {
          context.commit("SET_VOUCHER_PAGE", response.data.voucherPage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createVoucher(context, payload) {
      return await api
        .post(`/v1/voucher`, payload)
        .then((response) => {
          context.commit("ADD_VOUCHER", response.data.voucher);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Voucher created",
            },
            { root: true }
          );
          return response.data.voucher;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateVoucher(context, data: { id: string; voucher: Voucher }) {
      return await api
        .put(`/v1/voucher/${data.id}`, data.voucher)
        .then((response) => {
          context.commit("ADD_VOUCHER", response.data.voucher);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Voucher updated",
            },
            { root: true }
          );
          return response.data.voucher;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteVoucher(context, id) {
      api
        .delete(`/v1/voucher${id}`, {
          headers: { "vendor-module": "marketing.Gift Vouchers" },
        })
        .then((response) => {
          context.commit("REMOVE_VOUCHER", response.data.voucher);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Voucher deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async validateVoucher(context, payload) {
      return await api
        .post(`/v1/voucher/client/validate`, payload)
        .then((response) => {
          return response.data.valid;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default voucher;
